import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Headroom from 'react-headroom';
import Header from './headerr'
import '../scss/gatstrap.scss'


import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faCar, faShip, faMotorcycle, faCheckCircle, faMapMarkerAlt, faPhone, faEnvelope, faChevronUp } from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faCar, faShip, faMotorcycle, faCheckCircle, faMapMarkerAlt, faPhone, faEnvelope, faChevronUp);

class Layout extends React.Component{
     componentDidMount() {
          const WOW = require("wowjs")
          new WOW.WOW().init();
     }
     render() {
          return (
               <StaticQuery
                 query={graphql`
                   query SiteTitleQuery {
                     site {
                       siteMetadata {
                         title
                       }
                     }
                   }
                 `}
                 render={data => (
                   <>
                     <Helmet
                       title={data.site.siteMetadata.title}
                       meta={[
                         { name: 'description', content: 'Sample' },
                         { name: 'keywords', content: 'sample, something' },
                       ]}
                     >
                       <html lang="en" />
                     </Helmet>
                     <Headroom style={{zIndex:1000}}>
                          <Header siteTitle={data.site.siteMetadata.title} />
                     </Headroom>
                     <div>
                       {this.props.children}
                     </div>
                   </>
                 )}
               />
          );
     }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
