import React from 'react'
import {Navbar,NavbarBrand,NavbarToggler,Collapse,Nav,NavItem,NavLink} from 'reactstrap'
import Logo from '../../static/img/logo.png'


class Header extends React.Component{
     constructor(props) {
          super(props);

          this.toggle = this.toggle.bind(this);
          this.state = {
           isOpen: false
          };
     }
     toggle() {
          this.setState({
           isOpen: !this.state.isOpen
          });
     }
     render(){
          return(
               <header id="header">
                    <div className="container">
                         <Navbar light expand="md">
                           <NavbarBrand><img src={Logo} alt="Recapsure Insurance" title="Insurance" /></NavbarBrand>
                           <NavbarToggler onClick={this.toggle} />
                           <Collapse isOpen={this.state.isOpen} navbar>
                              <Nav className="ml-auto" navbar>
                               <NavItem><NavLink href="#for-agents">For Agents</NavLink></NavItem>
                               <NavItem><NavLink href="#insurance-products">Insurance Products</NavLink></NavItem>
                               <NavItem><NavLink href="#team">Team</NavLink></NavItem>
                               <NavItem><NavLink href="#contact">Contact</NavLink></NavItem>
                             </Nav>
                           </Collapse>
                         </Navbar>
                    </div>
               </header>
          )
     }
}
export default Header
